import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DataGridFlexSales, useDataGridFlexSales } from '../../../../DataGridFlexSales';

import {
  activeCountriesSelector,
  dashboardMonthlySalesMonthlySalesSelector,
  dashboardMonthlySalesRegisteredStoresSelector,
  masterDataSelector,
} from '../../../../../state';
import handlerFactory, { MonthlySalesDataGridContext } from './handlerFactory';
import { MonthlySale } from '../../../../../types';

const DataGridConfig = () => {
  const { handler } = useDataGridFlexSales<MonthlySale, MonthlySalesDataGridContext>();

  const { t } = useTranslation();
  const monthlySalesResource = useSelector(dashboardMonthlySalesMonthlySalesSelector);
  const registeredStores = useSelector(dashboardMonthlySalesRegisteredStoresSelector);
  const masterDataResource = useSelector(masterDataSelector);
  const activeCountriesResource = useSelector(activeCountriesSelector);

  const { data, loading: resourceLoading } = monthlySalesResource;
  const loading = resourceLoading || masterDataResource.loading || activeCountriesResource.loading;

  const gridContext = useMemo(() => ({ t, registeredStores }), [registeredStores, t]);

  handler.setData(data);
  handler.setContext(gridContext);
  handler.setLoading(loading);

  return null;
};

const MonthlySalesDataGrid = () => (
  <DataGridFlexSales handlerFactory={handlerFactory} hideToolbar>
    <DataGridConfig />
  </DataGridFlexSales>
);

export default MonthlySalesDataGrid;
