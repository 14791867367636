import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { MastersDetailSearch, MastersImportDataGrid } from '../components';
import FiltersLayoutHandler from '../components/FiltersLayoutHandler';
import {
  changeMastersImportFilterCountry,
  changeMastersImportFilterState,
  findMastersImportRequested,
  reportMastersImportLoadingSelector,
} from '../state';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .MuiToolbar-root {
    margin-top: ${props => props.theme.spacing(3)};
  }
`;

const MastersImport = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const state = searchParams.get('state');

  useEffect(() => {
    if (state) {
      dispatch(changeMastersImportFilterCountry([]));
      dispatch(changeMastersImportFilterState([state]));
      dispatch(findMastersImportRequested());
    }
  }, [dispatch, state]);

  return (
    <Container>
      <FiltersLayoutHandler selector={reportMastersImportLoadingSelector}>
        <MastersDetailSearch />
      </FiltersLayoutHandler>
      <MastersImportDataGrid />
    </Container>
  );
};

export default MastersImport;
