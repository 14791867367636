import { AppState } from './AppState';

export const preferencesSelector = (state: AppState) => state.preferences;
export const themeSelector = (state: AppState) => state.preferences.theme;

export const isAuthenticatedSelector = (state: AppState) => state.user.isAuthenticated;
export const userPermissionsSelector = (state: AppState) => state.user.permissions;
export const userPermissionsDataSelector = (state: AppState) => state.user.permissions.data;
export const userInfoSelector = (state: AppState) => state.user.info;
export const userInfoNameSelector = (state: AppState) => state.user.info?.name;

export const isModeModalSelector = (state: AppState) => state.layout.isModeModal;
export const isSidebarOpenSelector = (state: AppState) => state.layout.isSidebarOpen;

export const masterDataSelector = (state: AppState) => state.parameters.masterData;
export const masterDataDataSelector = (state: AppState) => state.parameters.masterData.data;
export const masterDataLoadedSelector = (state: AppState) => state.parameters.masterData.loaded;
export const activeCountriesSelector = (state: AppState) => state.parameters.activeCountries;
export const activeCountriesDataSelector = (state: AppState) =>
  state.parameters.activeCountries.data;
export const activeCountriesLoadedSelector = (state: AppState) =>
  state.parameters.activeCountries.loaded;

export const filterParametersSelector = (state: AppState) => state.parameters.parameters;
export const filterParametersDataSelector = (state: AppState) => state.parameters.parameters.data;
export const filterParametersLoadedSelector = (state: AppState) =>
  state.parameters.parameters.loaded;

export const reportPosOptionsSelector = (state: AppState) => state.report.posOptions;
export const reportSaleDiscountOptionsSelector = (state: AppState) =>
  state.report.saleDiscountOptions;
export const reportItemDiscountOptionsSelector = (state: AppState) =>
  state.report.itemDiscountOptions;
export const reportSalesTypesSelector = (state: AppState) => state.report.salesTypesOptions;
export const reportPaymentTypeOptionsSelector = (state: AppState) =>
  state.report.paymentTypeOptions;
export const reportOperationTypeOptionsSelector = (state: AppState) =>
  state.report.operationTypeOptions;
export const reportHourRangeSelector = (state: AppState) => state.report.hourRangeOptions;
export const reportTimeBandSelector = (state: AppState) => state.report.timeBandOptions;
export const reportFilterSelector = (state: AppState) => state.report.filters;
export const reportFilterCountrySelector = (state: AppState) => state.report.filters.country;
export const reportFilterCountryCodeSelector = (state: AppState) =>
  state.report.filters.countryCode;
export const reportFilterStoresSelector = (state: AppState) => state.report.filters.stores;
export const reportFilterSegmentsSelector = (state: AppState) => state.report.filters.segments;
export const reportFilterPosSelector = (state: AppState) => state.report.filters.pos;
export const reportFilterSaleDiscountSelector = (state: AppState) =>
  state.report.filters.saleDiscount;
export const reportFilterItemDiscountSelector = (state: AppState) =>
  state.report.filters.itemDiscount;
export const reportFilterDifferenceTypeSelector = (state: AppState) =>
  state.report.filters.differenceType;
export const reportFilterSalesTypesSelector = (state: AppState) => state.report.filters.salesTypes;
export const reportFilterPaymentTypesSelector = (state: AppState) =>
  state.report.filters.paymentTypes;
export const reportFilterOperationTypeSelector = (state: AppState) =>
  state.report.filters.operationType;
export const reportFilterHourRangeSelector = (state: AppState) => state.report.filters.hourRange;
export const reportFilterTimeBandSelector = (state: AppState) => state.report.filters.timeBand;
export const reportFilterStartDateSelector = (state: AppState) => state.report.filters.startDate;
export const reportFilterEndDateSelector = (state: AppState) => state.report.filters.endDate;
export const reportFilterStartBusinessDateSelector = (state: AppState) =>
  state.report.filters.startBusinessDate;
export const reportFilterEndBusinessDateSelector = (state: AppState) =>
  state.report.filters.endBusinessDate;
export const reportFilterStartOperationDateSelector = (state: AppState) =>
  state.report.filters.startOperationDate;
export const reportFilterEndOperationDateSelector = (state: AppState) =>
  state.report.filters.endOperationDate;
export const reportFilterStartAccountingDateSelector = (state: AppState) =>
  state.report.filters.startAccountingDate;
export const reportFilterEndAccountingDateSelector = (state: AppState) =>
  state.report.filters.endAccountingDate;
export const reportFilterGroupBySelector = (state: AppState) => state.report.filters.groupBy;
export const reportFilterSalesProductGroupBySelector = (state: AppState) =>
  state.report.filters.salesProductGroupBy;
export const reportFilterTenderGroupBySelector = (state: AppState) =>
  state.report.filters.tenderGroupBy;
export const reportFilterPluSelector = (state: AppState) => state.report.filters.plu;
export const reportFilterStoreMotherChildSelector = (state: AppState) => state.report.filters.pod;
export const reportFilterAppliedSelector = (state: AppState) => state.report.filterApplied;
export const reportFilterAccountingEntriesFieldsSelector = (state: AppState) =>
  state.report.filters.accountingEntriesFields;
export const reportSalesSelector = (state: AppState) => state.report.sales;
export const reportTenderSelector = (state: AppState) => state.report.tender;
export const reportSalesLoadingSelector = (state: AppState) => state.report.sales.loading;

export const reportSalesByProductLoadingSelector = (state: AppState) =>
  state.report.salesByProduct.loading;
export const reportSalesByProductSelector = (state: AppState) => state.report.salesByProduct;

export const reportGCsByProductLoadingSelector = (state: AppState) =>
  state.report.gcsByProduct.loading;
export const reportGCsByProductSelector = (state: AppState) => state.report.gcsByProduct;

export const reportTenderLoadingSelector = (state: AppState) => state.report.tender.loading;
export const reportGcsSelector = (state: AppState) => state.report.gcs;
export const reportGcsPaymentTypesSelector = (state: AppState) => state.report.gcsPaymentTypes;
export const reportGcsLoadingSelector = (state: AppState) => state.report.gcs.loading;
export const reportGcsPaymentTypesLoadingSelector = (state: AppState) =>
  state.report.gcsPaymentTypes.loading;
export const reportClosingSalesSelector = (state: AppState) => state.report.closing.sales;
export const reportClosingCollectionsSelector = (state: AppState) =>
  state.report.closing.collections;
export const reportClosingSalesLoadingSelector = (state: AppState) =>
  state.report.closing.sales.loading;
export const reportClosingCollectionsLoadingSelector = (state: AppState) =>
  state.report.closing.collections.loading;
export const reportClosingCurrentSelector = (state: AppState) => state.report.closing.current;
export const reportClosingSalesCausesSelector = (state: AppState) =>
  state.report.missingClosingCauses.salesCauses;
export const reportClosingSalesCausesLoadingSelector = (state: AppState) =>
  state.report.missingClosingCauses.salesCauses.loading;
export const reportMissingClosingCollectionsCausesSelector = (state: AppState) =>
  state.report.missingClosingCauses.collectionsCauses;
export const reportMissingClosingCollectionsCausesLoadingSelector = (state: AppState) =>
  state.report.missingClosingCauses.collectionsCauses.loading;
export const reportCauseTypesSelector = (state: AppState) => state.report.causeTypesOptions;
export const reportCausesFilterSelector = (state: AppState) => state.report.causesFilters;
export const reportCausesFilterCountrySelector = (state: AppState) =>
  state.report.causesFilters.country;
export const reportCausesFilterStoresSelector = (state: AppState) =>
  state.report.causesFilters.store;
export const reportCausesFilterDateSelector = (state: AppState) => state.report.causesFilters.date;
export const reportCausesFilterCauseSelector = (state: AppState) =>
  state.report.causesFilters.cause;
export const reportOpStructuresFilterSelector = (state: AppState) =>
  state.report.opStructuresFilters;
export const reportOpStructuresFilterAgreementSelector = (state: AppState) =>
  state.report.opStructuresFilters.agreement;
export const reportOpStructuresFilterRegionalManagementSelector = (state: AppState) =>
  state.report.opStructuresFilters.regionalMgmt;
export const reportOpStructuresFilterRegionSelector = (state: AppState) =>
  state.report.opStructuresFilters.region;
export const reportOpStructuresFilterManagementSelector = (state: AppState) =>
  state.report.opStructuresFilters.management;
export const reportOpStructuresFilterSupervisionSelector = (state: AppState) =>
  state.report.opStructuresFilters.supervision;
export const reportOpStructuresFilterCompanySelector = (state: AppState) =>
  state.report.opStructuresFilters.company;
export const reportMastersImportFilterSelector = (state: AppState) =>
  state.report.mastersImportFilters;
export const reportMastersImportFilterCountrySelector = (state: AppState) =>
  state.report.mastersImportFilters.countries;
export const reportMastersImportFilterStartImportDateSelector = (state: AppState) =>
  state.report.mastersImportFilters.startImportDate;
export const reportMastersImportFilterEndImportDateSelector = (state: AppState) =>
  state.report.mastersImportFilters.endImportDate;
export const reportMastersImportFilterStateSelector = (state: AppState) =>
  state.report.mastersImportFilters.state;
export const reportClosingFilterSelector = (state: AppState) => state.report.closingFilters;
export const reportClosingFilterCountrySelector = (state: AppState) =>
  state.report.closingFilters.countries;
export const reportClosingFilterStoresSelector = (state: AppState) =>
  state.report.closingFilters.stores;
export const reportClosingFilterStartBusinessDateSelector = (state: AppState) =>
  state.report.closingFilters.startBusinessDate;
export const reportClosingFilterEndBusinessDateSelector = (state: AppState) =>
  state.report.closingFilters.endBusinessDate;
export const reportClosingFilterViewModeSelector = (state: AppState) =>
  state.report.closingFilters.viewMode;
export const reportClosingFilterCauseTypesSelector = (state: AppState) =>
  state.report.closingFilters.causeTypes;
export const reportMissingClosingTotalMissingClosingTotalSelector = (state: AppState) =>
  state.report.missingClosingTotal.missingClosingTotal;
export const reportMissingClosingTotalFilteredStoresSelector = (state: AppState) =>
  state.report.missingClosingTotal.filteredStores;
export const reportReliefsSelector = (state: AppState) => state.report.reliefs;
export const reportReliefsLoadingSelector = (state: AppState) => state.report.reliefs.loading;
export const reportReliefsGroupBySelector = (state: AppState) =>
  state.report.filters.reliefsGroupBy;
export const reportCancellationsSelector = (state: AppState) => state.report.cancellations;
export const reportCancellationsLoadingSelector = (state: AppState) =>
  state.report.cancellations.loading;
export const reportCancellationsGroupBySelector = (state: AppState) =>
  state.report.filters.cancellationsGroupBy;
export const reportPettyCashDifferenceSelector = (state: AppState) =>
  state.report.pettyCashDifference;
export const reportPettyCashDifferenceByPaymentTypeSelector = (state: AppState) =>
  state.report.PettyCashDifferenceByPaymentType;
export const reportPettyCashDifferenceLoadingSelector = (state: AppState) =>
  state.report.pettyCashDifference.loading;
export const reportPettyCashDifferenceByPaymentTypeLoadingSelector = (state: AppState) =>
  state.report.PettyCashDifferenceByPaymentType.loading;

export const reportThirdPartySalesByItemsSelector = (state: AppState) =>
  state.report.thirdPartySalesByItems;
export const reportThirdPartySalesByItemsLoadingSelector = (state: AppState) =>
  state.report.thirdPartySalesByItems.loading;
export const reportThirdPartySalesByItemsGroupBySelector = (state: AppState) =>
  state.report.filters.thirdPartySalesByItemsGroupBy;
export const reportSalesJournalGcsSelector = (state: AppState) => state.report.salesJournal.gcs;
export const reportSalesJournalGcsLoadingSelector = (state: AppState) =>
  state.report.salesJournal.gcs.loading;
export const reportSalesJournalNcsSelector = (state: AppState) => state.report.salesJournal.ncs;
export const reportSalesJournalNcsLoadingSelector = (state: AppState) =>
  state.report.salesJournal.ncs.loading;
export const reportSalesJournalFcsSelector = (state: AppState) => state.report.salesJournal.fcs;
export const reportSalesJournalFcsLoadingSelector = (state: AppState) =>
  state.report.salesJournal.fcs.loading;
export const reportSalesJournalThirdPartySelector = (state: AppState) =>
  state.report.salesJournal.thirdparty;
export const reportSalesJournalThirdPartyLoadingSelector = (state: AppState) =>
  state.report.salesJournal.thirdparty.loading;
export const reportMastersImportSelector = (state: AppState) => state.report.mastersImport;
export const reportMastersImportLoadingSelector = (state: AppState) =>
  state.report.mastersImport.loading;
export const reportFilterDiscountsJoinSelector = (state: AppState) =>
  state.report.filters.discountsJoin;

export const reportMasterImportExecutionLoadingSelector = (state: AppState) =>
  state.report.mastersImportExecution.loading;

export const dashboardSalesClosingSelector = (state: AppState) => state.dashboard.sales.closing;
export const dashboardSalesViewModeSelector = (state: AppState) => state.dashboard.sales.viewMode;

export const dashboardCollectionsClosingSelector = (state: AppState) =>
  state.dashboard.collections.closing;
export const dashboardCollectionsViewModeSelector = (state: AppState) =>
  state.dashboard.collections.viewMode;

export const dashboardClosedMonthsAccountingMonthSelector = (state: AppState) =>
  state.dashboard.closedMonths.accountingMonth;
export const dashboardClosedMonthsClosedMonthsSelector = (state: AppState) =>
  state.dashboard.closedMonths.closedMonths;

export const dashboardMonthlySalesMonthSelector = (state: AppState) =>
  state.dashboard.monthlySales.month;
export const dashboardMonthlySalesRegisteredStoresSelector = (state: AppState) =>
  state.dashboard.monthlySales.registeredStores;
export const dashboardMonthlySalesMonthlySalesSelector = (state: AppState) =>
  state.dashboard.monthlySales.monthlySales;

export const dashboardDailySalesDailySalesSelector = (state: AppState) =>
  state.dashboard.dailySales.dailySales;
export const dashboardDailySalesDailySalesLoadingSelector = (state: AppState) =>
  state.dashboard.dailySales.dailySales.loading;

export const dashboardDailySalesDelayedStoresSelector = (state: AppState) =>
  state.dashboard.dailySales.delayedStores;

export const dashboardAutoUpdateTimerSelector = (state: AppState) => state.dashboard.autoUpdate;

export const dashboardMastersStatusMastersStatusDetailsSelector = (state: AppState) =>
  state.dashboard.masterStatus.mastersStatusDetails;
export const dashboardMastersStatusMastersStatusSelector = (state: AppState) =>
  state.dashboard.masterStatus.mastersStatus;
export const dashboardMastersStatus2Selector = (state: AppState) => state.dashboard.masterStatus2;

export const erroHttpErrorSelector = (state: AppState) => state.error.httpError;
export const erroValidationErrorsSelector = (state: AppState) => state.error.validationErrors;

export const reprocessParametersSelector = (state: AppState) => state.reprocess.parameters;
export const reprocessFilterSelector = (state: AppState) => state.reprocess.filters;
export const reprocessFilterCountryCodeSelector = (state: AppState) =>
  state.reprocess.filters.countryCode;
export const reprocessFilterFederativeEntityAcronymSelector = (state: AppState) =>
  state.reprocess.filters.federativeEntity;
export const reprocessFilterStoreAcronymSelector = (state: AppState) =>
  state.reprocess.filters.storeAcronym;
export const reprocessFilterBusinessDateSelector = (state: AppState) =>
  state.reprocess.filters.businessDate;
export const reprocessFilterReprocessTypeSelector = (state: AppState) =>
  state.reprocess.filters.reprocessType;
export const reprocessFilterStatusSelector = (state: AppState) => state.reprocess.filters.status;
export const reprocessReprocessLoadingSelector = (state: AppState) =>
  state.reprocess.reprocess.loading;
export const reprocessReprocessSelector = (state: AppState) => state.reprocess.reprocess;

export const companyCodesFilterSelector = (state: AppState) => state.companyCodes.filters;
export const companyCodesFilterCountryIdSelector = (state: AppState) =>
  state.companyCodes.filters.countryId;
export const companyCodesCompanyCodesLoadingSelector = (state: AppState) =>
  state.companyCodes.companyCodes.loading;
export const companyCodesCompanyCodesSelector = (state: AppState) =>
  state.companyCodes.companyCodes;

export const companyCodesCompanyCodeSelector = (state: AppState) => state.companyCodes.companyCode;

export const accountingParametersAccountingParametersSelector = (state: AppState) =>
  state.accountingParameters.accountingParameters;

export const accountingParametersAccountingParametersLoadingSelector = (state: AppState) =>
  state.accountingParameters.accountingParameters.loading;

export const accountingParametersAccountingParameterSelector = (state: AppState) =>
  state.accountingParameters.accountingParameter;

export const accountingParametersCountrySelector = (state: AppState) =>
  state.accountingParameters.country;

export const accountingEntriesFilterSelector = (state: AppState) => state.accountingEntries.filters;

export const accountingEntriesFilterCountrySelector = (state: AppState) =>
  state.accountingEntries.filters.country;
export const accountingEntriesCompanyCodesLoadingSelector = (state: AppState) =>
  state.accountingEntries.accountingEntries.loading;
export const accountingEntriesAccountingEntriesSelector = (state: AppState) =>
  state.accountingEntries.accountingEntries;
export const accountingEntriesFilterAccountingMonthSelector = (state: AppState) =>
  state.accountingEntries.filters.accountingMonth;
export const accountingEntriesFilterJournalTypeSelector = (state: AppState) =>
  state.accountingEntries.filters.journalType;
export const accountingEntriesFilterStatusSelector = (state: AppState) =>
  state.accountingEntries.filters.status;

export const accountingEntriesDownloadingReportIdSelector = (state: AppState) =>
  state.accountingEntries.downloadingReportId;
export const accountingEntriesDeleteSelector = (state: AppState) => state.accountingEntries.delete;

export const monthClosingFilterSelector = (state: AppState) => state.monthClosing.filters;
export const monthClosingFilterCountrySelector = (state: AppState) =>
  state.monthClosing.filters.country;
export const monthClosingLoadingSelector = (state: AppState) =>
  state.monthClosing.monthClosing.loading;
export const monthClosingSelector = (state: AppState) => state.monthClosing.monthClosing;
export const monthClosingAnnulSelector = (state: AppState) => state.monthClosing.annul;

export const oakBrookFilterSelector = (state: AppState) => state.oakBrook.filters;
export const oakBrookOakBrookSelector = (state: AppState) => state.oakBrook.oakBrook;
export const oakBrookOakBrookDataSelector = (state: AppState) => state.oakBrook.oakBrook.data;
export const oakBrookOakBrookLoadingSelector = (state: AppState) => state.oakBrook.oakBrook.loading;
export const oakBrookOakBrookLoadedSelector = (state: AppState) => state.oakBrook.oakBrook.loaded;
export const oakBrookCountryFilterSelector = (state: AppState) => state.oakBrook.filters.country;
export const oakBrookMonthFilterSelector = (state: AppState) => state.oakBrook.filters.month;

export const notificationsQueueSelector = (state: AppState) => state.notifications.queue;

export const exportsDownloadingSelector = (state: AppState) => state.exports.downloading;
