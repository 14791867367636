import { GridProps } from '@mui/material';
import { PutEffect, put } from 'redux-saga/effects';
import {
  AppState,
  dashboardClosedMonthsClosedMonthsSelector,
  dashboardCollectionsClosingSelector,
  dashboardDailySalesDailySalesSelector,
  dashboardMastersStatus2Selector,
  dashboardMastersStatusMastersStatusSelector,
  dashboardMonthlySalesMonthlySalesSelector,
  dashboardSalesClosingSelector,
  findClosedMonthsRequested,
  findClosingCollectionsStatusRequested,
  findClosingSalesStatusRequested,
  findDailySalesRequested,
  findMastersStatus2Requested,
  findMastersStatusRequested,
  findMonthlySalesRequested,
} from '../../state';
import { AppPermissions, RemoteResource } from '../../types';
import {
  CollectionsIncorporation,
  DailySalesIncorporation,
  MasterImport,
  MasterStatus,
  MonthClosingIncorporation,
  MonthlySalesIncorporation,
  SalesIncorporation,
} from './incorporations';

interface DashboardConfiguration {
  permissions: AppPermissions[];
  request?: PutEffect;
  component: JSX.Element;
  resource: (state: AppState) => RemoteResource<unknown[]>;
  gridProps?: GridProps;
}

const DashboardsConfiguration: Record<string, DashboardConfiguration> = {
  SALES: {
    component: <SalesIncorporation />,
    permissions: [AppPermissions.DASHBOARD_MISSINGS],
    request: put(findClosingSalesStatusRequested()),
    resource: dashboardSalesClosingSelector,
    gridProps: { xs: 12, sm: 12, md: 6, lg: 3, xl: 3 },
  },
  COLLECTIONS: {
    component: <CollectionsIncorporation />,
    permissions: [AppPermissions.DASHBOARD_MISSINGS],
    request: put(findClosingCollectionsStatusRequested()),
    resource: dashboardCollectionsClosingSelector,
    gridProps: { xs: 12, sm: 12, md: 6, lg: 3, xl: 3 },
  },
  MASTER_IMPORT: {
    component: <MasterImport />,
    permissions: [AppPermissions.DASHBOARD_MASTER_IMPORT],
    request: put(findMastersStatusRequested()),
    resource: dashboardMastersStatusMastersStatusSelector,
    gridProps: { xs: 12, sm: 12, md: 6, lg: 3, xl: 3 },
  },
  MASTER_STATUS: {
    component: <MasterStatus />,
    permissions: [AppPermissions.DASHBOARD_MASTER_IMPORT],
    request: put(findMastersStatus2Requested()),
    resource: dashboardMastersStatus2Selector,
    gridProps: { xs: 12, sm: 12, md: 6, lg: 3, xl: 3 },
  },
  MONTHLY_SALES: {
    component: <MonthlySalesIncorporation />,
    permissions: [AppPermissions.DASHBOARD_SALES_GCS],
    request: put(findMonthlySalesRequested()),
    resource: dashboardMonthlySalesMonthlySalesSelector,
    gridProps: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
  },
  DAILY_SALES: {
    component: <DailySalesIncorporation />,
    permissions: [AppPermissions.DASHBOARD_DAILY_SALES],
    request: put(findDailySalesRequested()),
    resource: dashboardDailySalesDailySalesSelector,
    gridProps: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
  },
  MONTH_CLOSING: {
    component: <MonthClosingIncorporation />,
    permissions: [AppPermissions.DASHBOARD_MONTH_CLOSING],
    request: put(findClosedMonthsRequested()),
    resource: dashboardClosedMonthsClosedMonthsSelector,
    gridProps: { xs: 12, sm: 12, md: 6, lg: 3, xl: 3 },
  },
};

const Dashboards = Object.keys(DashboardsConfiguration).map(key => ({
  ...DashboardsConfiguration[key],
  name: key,
}));

export default Dashboards;
