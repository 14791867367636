import {
  ClosingDashboardViewMode,
  ClosingStatus,
  DailySale,
  DashboardAutoUpdate,
  DelayedStores,
  HttpRequestError,
  MastersStatus,
  MasterStatusDetail,
  MonthClosing,
  MonthlySale,
  RegisteredStores,
} from '../../types';
import {
  ActionType,
  ChangeAccountingMonthAction,
  ChangeAutoUpdateAction,
  ChangeCollectionsViewModeAction,
  ChangeMonthAction,
  ChangeSalesViewModeAction,
  FindAllRequestAction,
  FindClosedMonthsFailureAction,
  FindClosedMonthsRequestedAction,
  FindClosedMonthsSuccessAction,
  FindClosingCollectionsStatusFailureAction,
  FindClosingCollectionsStatusRequestedAction,
  FindClosingCollectionsStatusSuccessAction,
  FindClosingSalesStatusFailureAction,
  FindClosingSalesStatusRequestedAction,
  FindClosingSalesStatusSuccessAction,
  FindDailySalesFailureAction,
  FindDailySalesRequestedAction,
  FindDailySalesSuccessAction,
  FindDelayedStoresFailureAction,
  FindDelayedStoresRequestedAction,
  FindDelayedStoresSuccessAction,
  FindMastersStatus2FailureAction,
  FindMastersStatus2RequestedAction,
  FindMastersStatus2SuccessAction,
  FindMastersStatusFailureAction,
  FindMastersStatusMastersStatusDetailsFailureAction,
  FindMastersStatusMastersStatusDetailsRequestedAction,
  FindMastersStatusMastersStatusDetailsSuccessAction,
  FindMastersStatusRequestedAction,
  FindMastersStatusSuccessAction,
  FindMonthlySalesFailureAction,
  FindMonthlySalesRequestedAction,
  FindMonthlySalesSuccessAction,
  FindRegisteredStoresFailureAction,
  FindRegisteredStoresRequestedAction,
  FindRegisteredStoresSuccessAction,
} from './types';

export const findClosingSalesStatusRequested = (): FindClosingSalesStatusRequestedAction => ({
  type: ActionType.findClosingSalesStatusRequested,
});

export const findClosingSalesStatusSuccess = (
  closing: ClosingStatus[]
): FindClosingSalesStatusSuccessAction => ({
  type: ActionType.findClosingSalesStatusSuccess,
  closing,
});

export const findClosingSalesStatusFailure = (
  error: HttpRequestError
): FindClosingSalesStatusFailureAction => ({
  type: ActionType.findClosingSalesStatusFailure,
  error,
});

export const changeSalesViewMode = (
  viewMode: ClosingDashboardViewMode
): ChangeSalesViewModeAction => ({
  type: ActionType.changeSalesViewMode,
  viewMode,
});

export const findClosingCollectionsStatusRequested =
  (): FindClosingCollectionsStatusRequestedAction => ({
    type: ActionType.findClosingCollectionsStatusRequested,
  });

export const findClosingCollectionsStatusSuccess = (
  closing: ClosingStatus[]
): FindClosingCollectionsStatusSuccessAction => ({
  type: ActionType.findClosingCollectionsStatusSuccess,
  closing,
});

export const findClosingCollectionsStatusFailure = (
  error: HttpRequestError
): FindClosingCollectionsStatusFailureAction => ({
  type: ActionType.findClosingCollectionsStatusFailure,
  error,
});

export const changeCollectionsViewMode = (
  viewMode: ClosingDashboardViewMode
): ChangeCollectionsViewModeAction => ({
  type: ActionType.changeCollectionsViewMode,
  viewMode,
});

export const findClosedMonthsRequested = (): FindClosedMonthsRequestedAction => ({
  type: ActionType.findClosedMonthsRequested,
});

export const findClosedMonthsSuccess = (
  closedMonths: MonthClosing[]
): FindClosedMonthsSuccessAction => ({
  type: ActionType.findClosedMonthsSuccess,
  closedMonths,
});

export const findClosedMonthsFailure = (
  error: HttpRequestError
): FindClosedMonthsFailureAction => ({
  type: ActionType.findClosedMonthsFailure,
  error,
});

export const changeAccountingMonth = (accountingMonth: Date): ChangeAccountingMonthAction => ({
  type: ActionType.changeAccountingMonth,
  accountingMonth,
});

export const findMonthlySalesRequested = (): FindMonthlySalesRequestedAction => ({
  type: ActionType.findMonthlySalesRequested,
});

export const findMonthlySalesSuccess = (
  monthlySales: MonthlySale[]
): FindMonthlySalesSuccessAction => ({
  type: ActionType.findMonthlySalesSuccess,
  monthlySales,
});

export const findMonthlySalesFailure = (
  error: HttpRequestError
): FindMonthlySalesFailureAction => ({
  type: ActionType.findMonthlySalesFailure,
  error,
});

export const findRegisteredStoresRequested = (): FindRegisteredStoresRequestedAction => ({
  type: ActionType.findRegisteredStoresRequested,
});

export const findRegisteredStoresSuccess = (
  registeredStores: RegisteredStores[]
): FindRegisteredStoresSuccessAction => ({
  type: ActionType.findRegisteredStoresSuccess,
  registeredStores,
});

export const findRegisteredStoresFailure = (
  error: HttpRequestError
): FindRegisteredStoresFailureAction => ({
  type: ActionType.findRegisteredStoresFailure,
  error,
});

export const changeMonth = (month: Date): ChangeMonthAction => ({
  type: ActionType.changeMonth,
  month,
});

export const findDailySalesRequested = (): FindDailySalesRequestedAction => ({
  type: ActionType.findDailySalesRequested,
});

export const findDailySalesSuccess = (dailySales: DailySale[]): FindDailySalesSuccessAction => ({
  type: ActionType.findDailySalesSuccess,
  dailySales,
});

export const findDailySalesFailure = (error: HttpRequestError): FindDailySalesFailureAction => ({
  type: ActionType.findDailySalesFailure,
  error,
});

export const findDelayedStoresRequested = (): FindDelayedStoresRequestedAction => ({
  type: ActionType.findDelayedStoresRequested,
});

export const findDelayedStoresSuccess = (
  delayedStores: DelayedStores[]
): FindDelayedStoresSuccessAction => ({
  type: ActionType.findDelayedStoresSuccess,
  delayedStores,
});

export const findDelayedStoresFailure = (
  error: HttpRequestError
): FindDelayedStoresFailureAction => ({
  type: ActionType.findDelayedStoresFailure,
  error,
});

export const changeAutoUpdateTimer = (autoUpdate: DashboardAutoUpdate): ChangeAutoUpdateAction => ({
  type: ActionType.changeAutoUpdate,
  autoUpdate,
});

export const findAllRequest = (): FindAllRequestAction => ({
  type: ActionType.findAllRequest,
});

export const findMastersStatusRequested = (): FindMastersStatusRequestedAction => ({
  type: ActionType.findMastersStatusRequested,
});

export const findMastersStatusSuccess = (
  mastersStatus: MastersStatus[]
): FindMastersStatusSuccessAction => ({
  type: ActionType.findMastersStatusSuccess,
  mastersStatus,
});

export const findMastersStatusFailure = (
  error: HttpRequestError
): FindMastersStatusFailureAction => ({
  type: ActionType.findMastersStatusFailure,
  error,
});

export const findMastersStatusMastersStatusDetailsRequested = (
  date: Date
): FindMastersStatusMastersStatusDetailsRequestedAction => ({
  type: ActionType.findMastersStatusMastersStatusDetailsRequested,
  date,
});

export const findMastersStatusMastersStatusDetailsSuccess = (
  mastersStatusDetails: MasterStatusDetail[]
): FindMastersStatusMastersStatusDetailsSuccessAction => ({
  type: ActionType.findMastersStatusMastersStatusDetailsSuccess,
  mastersStatusDetails,
});

export const findMastersStatusMastersStatusDetailsFailure = (
  error: HttpRequestError
): FindMastersStatusMastersStatusDetailsFailureAction => ({
  type: ActionType.findMastersStatusMastersStatusDetailsFailure,
  error,
});

export const findMastersStatus2Requested = (): FindMastersStatus2RequestedAction => ({
  type: ActionType.findMastersStatus2Requested,
});

export const findMastersStatus2Success = (
  mastersStatus: MastersStatus[]
): FindMastersStatus2SuccessAction => ({
  type: ActionType.findMastersStatus2Success,
  mastersStatus,
});

export const findMastersStatus2Failure = (
  error: HttpRequestError
): FindMastersStatus2FailureAction => ({
  type: ActionType.findMastersStatus2Failure,
  error,
});
