import { Action } from 'redux';
import {
  ClosingDashboardViewMode,
  ClosingStatus,
  DailySale,
  DashboardAutoUpdate,
  DelayedStores,
  HttpErrorBaseAction,
  MastersStatus,
  MasterStatusDetail,
  MonthClosing,
  MonthlySale,
  RegisteredStores,
  RemoteResource,
} from '../../types';

export interface State {
  sales: {
    viewMode: ClosingDashboardViewMode;
    closing: RemoteResource<ClosingStatus[]>;
  };
  collections: {
    viewMode: ClosingDashboardViewMode;
    closing: RemoteResource<ClosingStatus[]>;
  };
  closedMonths: {
    accountingMonth: Date;
    closedMonths: RemoteResource<MonthClosing[]>;
  };
  monthlySales: {
    month: Date;
    registeredStores: RemoteResource<RegisteredStores[]>;
    monthlySales: RemoteResource<MonthlySale[]>;
  };
  dailySales: {
    delayedStores: RemoteResource<DelayedStores[]>;
    dailySales: RemoteResource<DailySale[]>;
  };
  autoUpdate: DashboardAutoUpdate;
  masterStatus: {
    mastersStatus: RemoteResource<MastersStatus[]>;
    mastersStatusDetails: RemoteResource<MasterStatusDetail[]>;
  };
  masterStatus2: RemoteResource<MastersStatus[]>;
}

export enum ActionType {
  findClosingSalesStatusRequested = 'dashboard/findClosingSalesStatusRequested',
  findClosingSalesStatusSuccess = 'dashboard/findClosingSalesStatusSuccess',
  findClosingSalesStatusFailure = 'dashboard/findClosingSalesStatusFailure',
  changeSalesViewMode = 'dashboard/changeSalesViewMode',
  findClosingCollectionsStatusRequested = 'dashboard/findClosingCollectionsStatusRequested',
  findClosingCollectionsStatusSuccess = 'dashboard/findClosingCollectionsStatusSuccess',
  findClosingCollectionsStatusFailure = 'dashboard/findClosingCollectionsStatusFailure',
  changeCollectionsViewMode = 'dashboard/changeCollectionsViewMode',
  findClosedMonthsRequested = 'dashboard/findClosedMonthsRequested',
  findClosedMonthsSuccess = 'dashboard/findClosedMonthsSuccess',
  findClosedMonthsFailure = 'dashboard/findClosedMonthsFailure',
  changeAccountingMonth = 'dashboard/changeAccountingMonth',
  findMonthlySalesRequested = 'dashboard/findMonthlySalesRequested',
  findMonthlySalesSuccess = 'dashboard/findMonthlySalesSuccess',
  findMonthlySalesFailure = 'dashboard/findMonthlySalesFailure',
  findRegisteredStoresRequested = 'dashboard/findRegisteredStoresRequested',
  findRegisteredStoresSuccess = 'dashboard/findRegisteredStoresSuccess',
  findRegisteredStoresFailure = 'dashboard/findRegisteredStoresFailure',
  changeMonth = 'dashboard/changeMonth',
  findDailySalesRequested = 'dashboard/findDailySalesRequested',
  findDailySalesSuccess = 'dashboard/findDailySalesSuccess',
  findDailySalesFailure = 'dashboard/findDailySalesFailure',
  findDelayedStoresRequested = 'dashboard/findDelayedStoresRequested',
  findDelayedStoresSuccess = 'dashboard/findDelayedStoresSuccess',
  findDelayedStoresFailure = 'dashboard/findDelayedStoresFailure',
  changeAutoUpdate = 'dashboard/changeAutoUpdate',
  findAllRequest = 'dashboard/findAllRequest',
  findMastersStatusRequested = 'dashboard/findMastersStatusRequested',
  findMastersStatusSuccess = 'dashboard/findMastersStatusSuccess',
  findMastersStatusFailure = 'dashboard/findMastersStatusFailure',
  findMastersStatusMastersStatusDetailsRequested = 'dashboard/findMastersStatusDetailsRequested',
  findMastersStatusMastersStatusDetailsSuccess = 'dashboard/findMastersStatusDetailsSuccess',
  findMastersStatusMastersStatusDetailsFailure = 'dashboard/findMastersStatusDetailsFailure',
  findMastersStatus2Requested = 'dashboard/findMastersStatus2Requested',
  findMastersStatus2Success = 'dashboard/findMastersStatus2Success',
  findMastersStatus2Failure = 'dashboard/findMastersStatus2Failure',
}

export type FindClosingSalesStatusRequestedAction =
  Action<ActionType.findClosingSalesStatusRequested>;

export type FindClosingSalesStatusSuccessAction =
  Action<ActionType.findClosingSalesStatusSuccess> & {
    closing: ClosingStatus[];
  };

export type FindClosingSalesStatusFailureAction =
  HttpErrorBaseAction<ActionType.findClosingSalesStatusFailure>;

export type ChangeSalesViewModeAction = Action<ActionType.changeSalesViewMode> & {
  viewMode: ClosingDashboardViewMode;
};

export type FindClosingCollectionsStatusRequestedAction =
  Action<ActionType.findClosingCollectionsStatusRequested>;

export type FindClosingCollectionsStatusSuccessAction =
  Action<ActionType.findClosingCollectionsStatusSuccess> & {
    closing: ClosingStatus[];
  };

export type FindClosingCollectionsStatusFailureAction =
  HttpErrorBaseAction<ActionType.findClosingCollectionsStatusFailure>;

export type ChangeCollectionsViewModeAction = Action<ActionType.changeCollectionsViewMode> & {
  viewMode: ClosingDashboardViewMode;
};

export type FindClosedMonthsRequestedAction = Action<ActionType.findClosedMonthsRequested>;

export type FindClosedMonthsSuccessAction = Action<ActionType.findClosedMonthsSuccess> & {
  closedMonths: MonthClosing[];
};

export type FindClosedMonthsFailureAction = HttpErrorBaseAction<ActionType.findClosedMonthsFailure>;

export type ChangeAccountingMonthAction = Action<ActionType.changeAccountingMonth> & {
  accountingMonth: Date;
};

export type FindMonthlySalesRequestedAction = Action<ActionType.findMonthlySalesRequested>;

export type FindMonthlySalesSuccessAction = Action<ActionType.findMonthlySalesSuccess> & {
  monthlySales: MonthlySale[];
};

export type FindMonthlySalesFailureAction = HttpErrorBaseAction<ActionType.findMonthlySalesFailure>;

export type FindRegisteredStoresRequestedAction = Action<ActionType.findRegisteredStoresRequested>;

export type FindRegisteredStoresSuccessAction = Action<ActionType.findRegisteredStoresSuccess> & {
  registeredStores: RegisteredStores[];
};

export type FindRegisteredStoresFailureAction =
  HttpErrorBaseAction<ActionType.findRegisteredStoresFailure>;

export type ChangeMonthAction = Action<ActionType.changeMonth> & {
  month: Date;
};

export type FindDailySalesRequestedAction = Action<ActionType.findDailySalesRequested>;

export type FindDailySalesSuccessAction = Action<ActionType.findDailySalesSuccess> & {
  dailySales: DailySale[];
};

export type FindDailySalesFailureAction = HttpErrorBaseAction<ActionType.findDailySalesFailure>;

export type FindDelayedStoresRequestedAction = Action<ActionType.findDelayedStoresRequested>;

export type FindDelayedStoresSuccessAction = Action<ActionType.findDelayedStoresSuccess> & {
  delayedStores: DelayedStores[];
};

export type FindDelayedStoresFailureAction =
  HttpErrorBaseAction<ActionType.findDelayedStoresFailure>;

export type ChangeAutoUpdateAction = Action<ActionType.changeAutoUpdate> & {
  autoUpdate: DashboardAutoUpdate;
};

export type FindAllRequestAction = Action<ActionType.findAllRequest>;

export type FindMastersStatusRequestedAction = Action<ActionType.findMastersStatusRequested>;

export type FindMastersStatusSuccessAction = Action<ActionType.findMastersStatusSuccess> & {
  mastersStatus: MastersStatus[];
};

export type FindMastersStatusFailureAction =
  HttpErrorBaseAction<ActionType.findMastersStatusFailure>;

export type FindMastersStatusMastersStatusDetailsRequestedAction =
  Action<ActionType.findMastersStatusMastersStatusDetailsRequested> & {
    date: Date;
  };

export type FindMastersStatusMastersStatusDetailsSuccessAction =
  Action<ActionType.findMastersStatusMastersStatusDetailsSuccess> & {
    mastersStatusDetails: MasterStatusDetail[];
  };

export type FindMastersStatusMastersStatusDetailsFailureAction =
  HttpErrorBaseAction<ActionType.findMastersStatusMastersStatusDetailsFailure>;

export type FindMastersStatus2RequestedAction = Action<ActionType.findMastersStatus2Requested>;

export type FindMastersStatus2SuccessAction = Action<ActionType.findMastersStatus2Success> & {
  mastersStatus: MastersStatus[];
};

export type FindMastersStatus2FailureAction =
  HttpErrorBaseAction<ActionType.findMastersStatus2Failure>;

export type Actions =
  | FindClosingSalesStatusRequestedAction
  | FindClosingSalesStatusSuccessAction
  | FindClosingSalesStatusFailureAction
  | ChangeSalesViewModeAction
  | FindClosingCollectionsStatusRequestedAction
  | FindClosingCollectionsStatusSuccessAction
  | FindClosingCollectionsStatusFailureAction
  | ChangeCollectionsViewModeAction
  | FindClosedMonthsRequestedAction
  | FindClosedMonthsSuccessAction
  | FindClosedMonthsFailureAction
  | ChangeAccountingMonthAction
  | FindMonthlySalesRequestedAction
  | FindMonthlySalesSuccessAction
  | FindMonthlySalesFailureAction
  | FindRegisteredStoresRequestedAction
  | FindRegisteredStoresSuccessAction
  | FindRegisteredStoresFailureAction
  | ChangeMonthAction
  | FindDailySalesRequestedAction
  | FindDailySalesSuccessAction
  | FindDailySalesFailureAction
  | FindDelayedStoresRequestedAction
  | FindDelayedStoresSuccessAction
  | FindDelayedStoresFailureAction
  | ChangeAutoUpdateAction
  | FindMastersStatusRequestedAction
  | FindMastersStatusSuccessAction
  | FindMastersStatusFailureAction
  | FindMastersStatusMastersStatusDetailsRequestedAction
  | FindMastersStatusMastersStatusDetailsSuccessAction
  | FindMastersStatusMastersStatusDetailsFailureAction
  | FindMastersStatus2RequestedAction
  | FindMastersStatus2SuccessAction
  | FindMastersStatus2FailureAction;
