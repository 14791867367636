import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import { createSearchParams, Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Skeleton } from '@mui/material';
import {
  activeCountriesSelector,
  dashboardMastersStatus2Selector,
  masterDataSelector,
} from '../../../../state';
import { useMuiPickersLocale } from '../../../../hooks';
import { getStatusColor } from '../../../../helpers';
import routes from '../../../../config/routes';

const ItemsContainer = styled(Stack)`
  padding-top: ${prop => prop.theme.spacing(2)};
  padding-bottom: ${prop => prop.theme.spacing(1)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > a {
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

interface StatusButtonProps {
  importDate: Date;
  status: string;
}

const StatusButton = ({ importDate, status }: StatusButtonProps) => {
  const { t } = useTranslation();
  const locale = useMuiPickersLocale();
  const to = {
    pathname: routes.protectedRoutes.mastersImport.path,
    search: `?${createSearchParams({ state: 'ERROR' })}`,
  };
  return (
    <Container>
      <StyledLink to={to}>
        <div>{format(importDate, 'P', { locale })}</div>
        <Typography variant="body1" color={getStatusColor(status)}>
          <Box component="span" fontWeight="bold">
            {t(`masters-imports.states.${status}`)}
          </Box>
        </Typography>
      </StyledLink>
    </Container>
  );
};

const IncorporationError = () => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" color="error" padding={1} spacing={1}>
      <ErrorIcon color="error" />
      <Typography variant="body1" color="error">
        {t('errors.generics.loadingData')}
      </Typography>
    </Stack>
  );
};

const MasterContent = () => {
  const { data, loading: resourceLoading, error } = useSelector(dashboardMastersStatus2Selector);
  const masterDataResource = useSelector(masterDataSelector);
  const activeCountriesResource = useSelector(activeCountriesSelector);

  const loading = resourceLoading || masterDataResource.loading || activeCountriesResource.loading;

  return (
    <ItemsContainer spacing={1}>
      {!error &&
        !loading &&
        data.map(i => (
          <StatusButton key={i.importDate.toString()} importDate={i.importDate} status={i.status} />
        ))}
      {loading && (
        <>
          <Skeleton />
          <Skeleton />
        </>
      )}
      {!loading && error && <IncorporationError />}
    </ItemsContainer>
  );
};

export default MasterContent;
