import { MonthlySale, RegisteredStores, RemoteResource } from '../../../../../types';
import { commonColProps, DataGridHandler } from '../../../../DataGridFlexSales';
import RegisteredStoresCell from './RegisteredStoresCell';

export interface MonthlySalesDataGridContext {
  t: (key: string | string[]) => string;
  registeredStores: RemoteResource<RegisteredStores[]>;
}

const initialContext: MonthlySalesDataGridContext = {
  t: () => '',
  registeredStores: { loading: false, loaded: false, error: null, data: [], failure: false },
};

export default (locale: Locale) => {
  const handler = new DataGridHandler<MonthlySale, MonthlySalesDataGridContext>(
    initialContext,
    locale
  );

  handler.addColumn({
    name: 'country',
    type: 'string',
    title: ({ t }) => t('table.columns.country'),
    visibilityTogglingDisabled: true,
    valueGetter: (row, { t }) => t(`operativeStructure.countries.${row.country}`),
    ...commonColProps.country,
  });

  handler.addColumn({
    accessor: 'gcs',
    type: 'number',
    title: ({ t }) => t('table.columns.gcs'),
  });

  handler.addColumn({
    accessor: 'netSale',
    type: 'number',
    title: ({ t }) => t('dashboard.monthly-sale.columns.net-sale'),
  });

  handler.addColumn({
    name: 'registeredStores',
    type: 'number',
    title: ({ t }) => t('dashboard.monthly-sale.columns.registered-stores'),
    valueGetter: row => row.registeredStores,
    renderCell: (_value, row, { registeredStores }) => (
      <RegisteredStoresCell country={row.country} registeredStores={registeredStores} />
    ),
  });

  return handler;
};
